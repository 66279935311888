<template>
    <div class="desInner des01">
        <div class="desImg" data-aos="fade-right"
             data-aos-duration="3000">
            <img alt="" src="img/taiwan.png"/>
        </div>
        <div class="desText" data-aos="fade-left"
             data-aos-duration="2000">
            <div class="mainTitle">
                <p>貨物共乘 大小不拘</p>
            </div>
            <div class="desUnitTxt">
                <p>單件運輸沒問題，到府收送有效率</p>
                <p>一週行程都給你，安排車趟方便行</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Introduction"
    };

    import AOS from 'aos';
    import 'aos/dist/aos.css';

    AOS.init();
</script>