<template>
    <div class="my-5 step-1" data-aos="fade-up" data-aos-duration="2000">
        <!-- 標題 -->
        <h3 class="text-dark font-weight-bold mb-10">
            STEP01｜取貨資訊
        </h3>

        <!-- 時間 -->
        <b-form-datepicker
                :date-disabled-fn="dateDisabled"
                :locale="locale"
                :min="minDate"
                @input="input"
                id="sender-datepicker"
                label-no-date-selected="請選擇時間"
                placeholder="由於派單程序較繁雜，行政處理上需要時間處理，目前只接受三天前的預約訂單！請點擊選擇日期"
                size="sm"
                v-model="parameters.time"/>
        <br/>

        <!-- 姓名 -->
        <div>
            <label>請輸入 取貨者姓名</label>
            <b-form-input v-model="parameters.name"/>
            <br/>
        </div>

        <!-- 地區 -->
        <div>
            <label>請選擇地區</label>
            <b-form-select
                    :options="this.geographicCountry"
                    v-model="parameters.selectCountry"/>
            <br/>
            <br/>
        </div>

        <!-- 地址 -->
        <div v-if="canShowAddress">
            <label>請輸入 取貨地址</label>
            <b-form-textarea v-model="parameters.address"/>
            <br/>
        </div>

        <!-- 手機號碼 -->
        <label>請輸入 手機號碼, 比如 0912345678</label>
        <b-form-input
                :state="phoneState"
                type="number"
                v-model="parameters.phone"/>
        <br/>

        <!-- @TODO: 司機搬運這邊先 mark 掉

        // 是否需要司機搬運
        <b-form-group label="是否需要司機搬運">
            <b-form-radio v-model="parameters.needPorter" value="1">
                是 ( 每一層樓梯+200 )
            </b-form-radio>
            <b-form-radio v-model="parameters.needPorter" value="0">
                否
            </b-form-radio>
        </b-form-group>

        // 樓層
        <div v-if="canShowFloor === '1'">
            <label>請輸入取貨樓層</label>
            <b-form-spinbutton max="100" min="-10" v-model="parameters.floor"/>
            <br/>
        </div>

        // 是否有電梯
        <b-form-group label="是否有電梯" v-if="canShowElevator">
            <b-form-radio
                    name="sender-hasElevator"
                    v-model="parameters.hasElevator"
                    value="1">
                是 ( + 100 )
            </b-form-radio>
            <b-form-radio
                    name="sender-hasElevator"
                    v-model="parameters.hasElevator"
                    value="0">
                否 ( 1 樓層 + 200 )
            </b-form-radio>
        </b-form-group>
        -->

        <div>
            <label>請選擇方案</label>
            <b-form-select
                    :options="this.getItemData.plans()"
                    v-model="plan"/>

            <!-- 需要跳出大盒子 -->
            <div v-if="isShowAddBox">
                <b-input-group append="個大盒子 ( 300 / 1 個 )" prepend="加購">
                    <b-form-input type="number" v-model="parameters.box"/>
                </b-input-group>
            </div>

            <!-- 貨品描敘 -->
            <v-textarea
                    auto-grow
                    filled
                    label="如有任何問題請這邊留言或者洽客服，官方ＬＩＮＥ： @412aiabg"
                    v-if="isShowAddBox"
                    v-model="parameters.plan4_note"/>
        </div>
        <br/>

        <!-- 加購摩托車保護套 -->
        <label>加值服務</label>
<!--        <b-form-checkbox-->
<!--                id="checkbox_is_need_motorcycle_protection"-->
<!--                name="checkbox_is_need_motorcycle_protection"-->
<!--                unchecked-value="0"-->
<!--                v-model="parameters.need_motorcycle_protection"-->
<!--                value="1">-->
<!--            加購摩托車保護套 ( 200 / 1 個 )-->
<!--        </b-form-checkbox>-->

        <b-form-checkbox
                id="checkbox_need_porter_depends_on_situation"
                name="checkbox_need_porter_depends_on_situation"
                unchecked-value="0"
                v-model="parameters.need_porter_depends_on_situation"
                value="1">
            需要搬運至電梯或上樓（樓梯一樓加收50元/箱，有電梯不論樓層則加收30元/箱，請於備註欄註明電梯或樓層）
        </b-form-checkbox>
        <br/>

        <!-- 其他備註 -->
        <div>
            <label>請輸入 備註 ( 請填寫需搬運之大型物品，如沒有大型物件者填無 )</label>
            <b-form-textarea @input="inputNote" v-model="parameters.note"/>
            <br/>
        </div>

        <b-button @click="actionToggle('a')" squared>關於寄送</b-button>
        <b-button @click="actionToggle('b')" squared>關於方案</b-button>
        <b-button @click="actionToggle('c')" squared>關於賠償</b-button>
        <b-button @click="actionToggle('d')" squared>退費機制</b-button>
        <b-button @click="actionToggle('e')" squared>運送範圍</b-button>
        <b-button @click="actionToggle('f')" squared>箱子折抵大型物件標準</b-button>

        <div v-if="this.currentCollapse === 'f' && this.visible === true">
            <br/>
            <label>單物件重量不超過40公斤，不大於200公分</label>
            <br/>
        </div>

        <b-collapse class="mt-2" id="collapse-a" v-model="visible">
            <b-table
                    :bordered="true"
                    :fields="getHeader"
                    :items="getItems"
                    head-variant="dark"
                    sticky-header/>
        </b-collapse>
    </div>
</template>

<script>
    import ItemData from "./Data.js";

    export default {
        name: "Step1",
        props: {
            geographic: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            plan: "plan5",

            locale: "zh",
            visible: false,

            currentCollapse: "",

            parameters: {
                // 箱子
                box: 0,
                // 方案
                plan: "plan5",
                // 摩托車保護
                need_motorcycle_protection: 0,
                // 需要搬運至電梯或上樓（樓梯一樓加收50元/箱，有電梯不論樓層則加收30元/箱，請於備註欄註明電梯或樓層）
                need_porter_depends_on_situation: 0,
                // 方案 4 描述
                plan4_note: null,

                time: null,
                selectCountry: null,
                name: null,
                floor: 1,
                address: null,
                phone: null,
                note: null,
                hasElevator: 0,
                needPorter: 0
            }
        }),
        components: {},
        created() {
        },
        watch: {
            // whenever question changes, this function will run
            plan: function (newPlan) {
                this.parameters.plan = newPlan;
                // Reset box to 0, while change plan
                this.parameters.box = 0;
                this.parameters.plan4_note = null;
            }
        },
        computed: {
            minDate() {
                const date = new Date();
                date.setDate(date.getDate() + 3);
                return date;
            },

            phoneState() {
                if (this.parameters.phone) {
                    return this.parameters.phone.length === 10;
                } else {
                    return null;
                }
            },

            getItemData() {
                return ItemData;
            },

            getHeader() {
                if (this.currentCollapse === "f") {
                    return this.getItemData.headerF();
                }
                return this.getItemData.header();
            },

            getItems() {
                if (this.currentCollapse === "a") {
                    return this.getItemData.itemsA();
                }
                if (this.currentCollapse === "b") {
                    return this.getItemData.itemsB;
                }
                if (this.currentCollapse === "c") {
                    return this.getItemData.itemsC;
                }
                if (this.currentCollapse === "d") {
                    return this.getItemData.itemsD;
                }
                if (this.currentCollapse === "e") {
                    return this.getItemData.itemsE;
                }
                if (this.currentCollapse === "f") {
                    return this.getItemData.itemsF;
                }
                return [];
            },

            isShowAddBox() {
                return this.plan !== 'plan4'
            },

            geographicCountry() {
                return this.geographic.map(city => {
                    city["label"] = city["name"];
                    city["options"] = city["townships"].map(township => {
                        const canSend = township["status_of_sender"] === 0;
                        const text = canSend ? " ( 此區暫時不支援 )" : "";
                        township["value"] = township["id"];
                        township["text"] = township["name"] + text;
                        township["disabled"] = canSend;
                        return township;
                    });
                    return city;
                });
            },

            canShowAddress() {
                return this.parameters.selectCountry;
            },

            canShowFloor() {
                return this.parameters.needPorter;
            },

            canShowElevator() {
                return this.parameters.floor !== 1 && this.parameters.floor !== 0;
            }
        },
        methods: {
            dateDisabled(ymd) {
                return (
                    ymd === "2022-01-29" ||
                    ymd === "2022-01-30"
                );
            },

            actionToggle(newValue) {
                if (newValue !== this.currentCollapse) {
                    this.visible = true;
                    this.currentCollapse = newValue;
                } else {
                    this.visible = !this.visible;
                }
            },

            input(date) {
                this.$emit("interface", date);
            },

            inputNote(data) {
                this.$emit("interfaceNote", data);
            }
        }
    };
</script>
