<template>
    <div class="mainContent formContent">
        <div class="mainTitle" data-aos="fade-up" data-aos-duration="2000">
            <p>快速下訂</p>
        </div>
        <div class="formInner">
            <div class="container" v-if="geographic">
                <!-- Step1 -->
                <step1 :geographic="geographic"
                       @interface="syncEmittedDate"
                       @interfaceNote="syncEmittedNote"
                       ref="step1"/>

                <!-- Step2 -->
                <step2 :geographic="geographic" ref="step2"/>

                <!-- Step3 -->
                <step3 ref="step3"/>

                <!-- Step4 -->
                <step4 ref="step4"/>

                <!-- Submit -->
                <div class="card-footer formBtn"
                     data-aos="fade-down"
                     data-aos-duration="2000">
                    <div class="ml-lg-auto">
                        <button
                                :disabled="isDisable"
                                @click="actionSent"
                                class="btn btn-success mr-2"
                                type="submit">
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Step1 from "./Step1";
    import Step2 from "./Step2";
    import Step3 from "./Step3";
    import Step4 from "./Step4";
    import {FETCH_GEOGRAPHIC} from "@/core/services/store/metadata.module";
    import {CREATE_ORDER} from "@/core/services/store/order.module";

    export default {
        name: "ContactUs",
        data: () => ({
            geographic: [],
            isDisable: false,

            status: true

            // promoteCode: ''
        }),
        components: {
            Step1,
            Step2,
            Step3,
            Step4
        },
        computed: {
            // geographic() {
            //   return this.$store.state.geographic;
            // },
            buttonText() {
                return this.isDisable ? "請輸入完整" : "送出";
            }
        },
        created() {
            this.$store.dispatch(FETCH_GEOGRAPHIC).then(({data}) => {
                this.geographic = data;
            });
        },
        methods: {
            actionSent() {
                if (!this.parameterValidation()) {
                    return;
                }

                const step1 = this.$refs.step1.parameters;
                const step2 = this.$refs.step2.parameters;
                const step3 = this.$refs.step3.parameters;

                const href = new URL(window.location.href);

                const parameters = {
                    referrer: href.searchParams.get("referrer") || null,
                    sender: step1,
                    picker: step2,
                    payment: step3
                };

                // const parameters = {
                //     referrer: null,
                //
                //     sender: {
                //         address: "取貨地址",
                //         box: "100",
                //         floor: 2,
                //         hasElevator: "1",
                //         name: "取貨姓名",
                //         needPorter: "1",
                //         need_motorcycle_protection: "1",
                //         need_porter_depends_on_situation: "1",
                //         note: "取貨備註",
                //         phone: "1234567872",
                //         plan: "plan7",
                //         plan4_note: 'plan4_note',
                //         selectCountry: 2,
                //         time: "2021-08-14",
                //     },
                //
                //     picker: {
                //         address: "領貨地址",
                //         floor: 2,
                //         hasElevator: "1",
                //         name: "領貨姓名",
                //         needPorter: "1",
                //         note: "領貨備註",
                //         phone: "1234878123",
                //         selectCountry: 1,
                //         time: "2021-08-14",
                //         need_porter_depends_on_situation: 0,
                //     },
                //
                //     payment: {
                //         'display_name': '超商和ATM匯款',
                //         'value': 'remittance'
                //     },
                // };

                // console.log(" log ****** =>", parameters);

                this.$store
                    .dispatch(CREATE_ORDER, parameters)
                    .then(() => {
                        this.$router.push(
                            {
                                name: 'thanks',
                                params: parameters
                            });
                    })
                    .catch(e => {
                        alert(e.toString());
                    });
            },

            parameterValidation() {

                let result = false;

                // ------------------- 同意條款 -------------------------

                const step4 = this.$refs.step4;

                if (!step4.status) {
                    alert("請先同意服務條款");
                    return result;
                }

                // ------------------- 取貨 -------------------------

                const step1 = this.$refs.step1.parameters;
                const step2 = this.$refs.step2.parameters;

                // ------------------- 取貨 -------------------------

                if (!step1.time) {
                    alert("請輸入 取貨時間");
                    return result;
                }

                if (!step1.name) {
                    alert("請輸入 取貨姓名");
                    return result;
                }

                if (!step1.selectCountry || !step1.address) {
                    alert("請輸入 取貨地區");
                    return result;
                }

                if (!step1.phone) {
                    alert("請輸入 取貨手機號碼");
                    return result;
                }

                // ------------------- 領貨 -------------------------

                if (!step2.time) {
                    alert("請輸入 領貨時間");
                    return result;
                }

                if (!step2.name) {
                    alert("請輸入 領貨姓名");
                    return result;
                }

                if (!step2.selectCountry || !step2.address) {
                    alert("請輸入 領貨地區");
                    return result;
                }

                if (!step2.phone) {
                    alert("請輸入 領貨手機號碼");
                    return result;
                }

                result = true;

                return result;
            },

            syncEmittedDate(date) {
                this.$refs.step2.parameters.time = date;
            },

            syncEmittedNote() {
                // this.$refs.step2.parameters.note = note
            }
        }
    };
</script>
