<template>
    <div class="desInner des01">
        <div class="desText" data-aos="fade-right" data-aos-duration="3000">
            <div class="mainTitle">
                <p>共享物流，共享價錢</p>
            </div>
        </div>
        <div class="desImg" data-aos="fade-left" data-aos-duration="2000">
            <img loading=lazy alt="" src="img/share.jpg" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Share"
    };

    import AOS from 'aos';
    import 'aos/dist/aos.css';

    AOS.init();
</script>