<template>
    <div class="my-5 step-2" data-aos="fade-up" data-aos-duration="2000">
        <!-- 標題 -->
        <h3 class="text-dark font-weight-bold mb-10">
            STEP02｜領貨資訊
        </h3>

        <!-- 時間 -->
        <label>目前只支援當天或隔日收送</label>
        <b-form-datepicker
                :disabled="true"
                :locale="locale"
                :min="min"
                label-no-date-selected="請選擇時間"
                placeholder="目前只支援當天或隔日收送"
                size="sm"
                v-model="parameters.time"/>
        <br/>

        <!-- 姓名 -->
        <div>
            <label>請輸入 領貨者姓名</label>
            <b-form-input size="sm" v-model="parameters.name"/>
            <br/>
        </div>

        <!-- 地區 -->
        <div>
            <label>請選擇地區</label>
            <b-form-select
                    :options="this.geographicCountry"
                    v-model="parameters.selectCountry"/>
            <br/>
            <br/>
        </div>

        <!-- 地址 -->
        <div v-if="canShowAddress">
            <label>請輸入 領貨地址</label>
            <b-form-textarea v-model="parameters.address"/>
            <br/>
        </div>

        <!-- 手機號碼 -->
        <label>請輸入 手機號碼, 比如 0912345678</label>
        <b-form-input
                :state="phoneState"
                type="number"
                v-model="parameters.phone"/>
        <br/>

<!--        &lt;!&ndash; 是否需要司機搬運 &ndash;&gt;-->
<!--        <b-form-group label="是否需要司機搬運">-->
<!--            <b-form-radio v-model="parameters.needPorter" value="1">-->
<!--                是 ( 每一層樓梯+200 )-->
<!--            </b-form-radio>-->
<!--            <b-form-radio v-model="parameters.needPorter" value="0">-->
<!--                否-->
<!--            </b-form-radio>-->
<!--        </b-form-group>-->

<!--        &lt;!&ndash; 樓層 &ndash;&gt;-->
<!--        <div v-if="canShowFloor === '1'">-->
<!--            <label>請輸入領貨樓層</label>-->
<!--            <br/>-->
<!--            <b-form-spinbutton max="100" min="-10" v-model="parameters.floor"/>-->
<!--            <br/>-->
<!--        </div>-->

        <!-- 是否有電梯 -->
<!--        <b-form-group label="是否有電梯" v-if="canShowElevator">-->
<!--            <b-form-radio v-model="parameters.hasElevator" value="1">-->
<!--                是 ( + 100 )-->
<!--            </b-form-radio>-->
<!--            <b-form-radio-->
<!--                    name="sender-hasElevator"-->
<!--                    v-model="parameters.hasElevator"-->
<!--                    value="0">-->
<!--                否 ( 1 樓層 + 200 )-->
<!--            </b-form-radio>-->
<!--        </b-form-group>-->

        <!-- 其他備註 -->
        <div>
            <label>請輸入 備註</label>
            <b-form-textarea v-model="parameters.note"/>
            <br/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Step2",
        props: {
            geographic: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            min: new Date(),
            locale: "zh",

            parameters: {
                selectCountry: null,
                name: null,
                address: null,
                floor: 1,
                phone: null,
                note: null,
                time: null,
                hasElevator: 0,
                needPorter: 0
            }
        }),
        components: {},
        created() {
        },
        computed: {
            phoneState() {
                if (this.parameters.phone) {
                    return this.parameters.phone.length === 10;
                } else {
                    return null;
                }
            },

            geographicCountry() {
                return this.geographic.map(city => {
                    city["label"] = city["name"];
                    city["options"] = city["townships"].map(township => {
                        const canPicker = township["status_of_picker"] === 0;
                        const text = canPicker ? " ( 此區暫時不支援 )" : "";
                        township["value"] = township["id"];
                        township["text"] = township["name"] + text;
                        township["disabled"] = canPicker;
                        return township;
                    });
                    return city;
                });
            },

            canShowAddress() {
                return this.parameters.selectCountry;
            },

            canShowFloor() {
                return this.parameters.needPorter;
            },

            canShowElevator() {
                return this.parameters.floor !== 1 && this.parameters.floor !== 0;
            }
        },
        methods: {}
    };
</script>
