<template>
    <div class="my-5 step-2" data-aos="fade-up" data-aos-duration="2000">
        <!-- 標題 -->
        <h3 class="text-dark font-weight-bold mb-10">
            STEP03｜付款方式
        </h3>

        <!-- 付款方式 -->
        <b-form-group label="請選擇希望付款方式">
            <b-form-radio :key="method.value"
                          :value="method"
                          v-for="(method) in methods"
                          v-model="parameters">
                {{ method.display_name }}
            </b-form-radio>
        </b-form-group>
    </div>
</template>

<script>
    export default {
        name: "Step3",
        data: () => ({
            methods: [
                {
                    'display_name': '銀行轉帳(付款完成訂單才會成立)',
                    'value': 'remittance'
                },
                // {
                //     'display_name': '現金預付',
                //     'value': 'cash_before_deliver'
                // },
                // {
                //     'display_name': '現金後付',
                //     'value': 'cash_after_deliver'
                // },
            ],

            parameters: {
                'display_name': '銀行轉帳(付款完成訂單才會成立)',
                'value': 'remittance',
                'success_router': 'thanks',
            }
        }),
        components: {},
        created() {
        },
        computed: {},
        methods: {}
    };

</script>
