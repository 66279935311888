<template>
    <div class="mainContent aboutContent">
        <div class="mainTitle" data-aos="fade-down"
             data-aos-duration="2000">
            <p>關於我們</p>
        </div>
        <div class="container">
            <div class="aboutText" data-aos="fade-up"
                 data-aos-duration="2000">
                <p>
                    三分鐘即可快速下單、價格透明、專為學生、上班族及小資族服務
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AboutUs"
    };
</script>

