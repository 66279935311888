<template>
    <div class="carouselInner">
        <div class="container">
            <div class="mainTitle" data-aos="fade-down"
                 data-aos-duration="2000">
                <p>優惠方案</p>
            </div>
            <swiper :navigation="true" :options="swiperOption" class="swiper">
                <swiper-slide v-bind:key="banner" v-for="banner in banners">
                    <img :src="banner" alt="no image"/>
                </swiper-slide>
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Carousel",
        // property
        props: {
            banners: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                swiperOption: {
                    autoplay: {
                        disableOnInteraction: false,
                        stopOnLastSlide: false,
                        delay: 2000
                    },
                    speed: 800,
                    grabCursor: true,
                    loop: true,
                    freeMode: true,
                    // centeredSlides: true,
                    breakpoints: {
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        991: {
                            slidesPerView: 3,
                            spaceBetween: 10
                        },
                        800: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 5
                        },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        }
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        type: "bullets",
                        clickable: true
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                    // pagination: {
                    //   el: ".swiper-pagination",
                    //   clickable: true,
                    //   type: "bullets"
                    // },
                    // navigation: {
                    //   nextEl: ".swiper-button-next",
                    //   prevEl: ".swiper-button-prev"
                    // }
                }
            };
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper;
            }
        },
        mounted() {
        },
        methods: {}
    };
</script>
