<template>
    <div class="desInner des02">
        <div class="mainTitle" data-aos="fade-up"
             data-aos-duration="3000">
            <p>BigBox共享物流</p>
        </div>
        <div class="des02Tab">
            <div class="desImg" data-aos="fade-right"
                 data-aos-duration="2000">
                <img alt="" src="img/boxes-amico.svg"/>
            </div>
            <div class="desUnitTxt" data-aos="fade-left"
                 data-aos-duration="2000">
                <ul>
                    <li>到府收件</li>
                    <li>AI派車</li>
                    <li>運送貨物包裝完善</li>
                    <li>專員客服服務</li>
                    <li>視貨物如己出</li>
                </ul>
                <ul>
                    <li>企業專屬方案</li>
                    <li>VIP特約方案</li>
                    <li>學生專案</li>
                    <li>不定期優惠方案</li>
                    <li>---歡迎長期配合！</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Description"
    }
</script>

<style scoped>

</style>