<template>
    <div class="indexContainer">
        <div class="container-fluid bmd-layout-container bmd-drawer-f-l bmd-drawer-overlay">

            <banner :banners="banner01"/>

            <main class="mainContainer">
                <about-us/>

                <div class="description">
                    <introduction/>

                    <share/>

                    <description/>

<!--                    <plan/>-->
                </div>

                <carousel :banners="banner02"/>

                <contact-us/>

                <Footer/>
            </main>
        </div>
    </div>
</template>

<script>
    import Footer from "./Footer";
    import Banner from "@/view/components/Banner";
    import AboutUs from "@/view/components/AboutUs";
    import Carousel from "@/view/components/Carousel";
    import Introduction from "@/view/components/Introduction";
    import Description from "@/view/components/Description";
    // import Plan from "@/view/components/Plan";
    import ContactUs from "@/view/components/ContactUs/Index";
    import Share from "@/view/components/Share";

    export default {
        name: "Dashboard",
        data: function () {
            return {
                banner01: [
                    // "img/banner1.jpg",
                    "img/banner10.jpg",
                    // 把不是合照的拿掉
                    // "img/banner20.jpg",
                    // "img/banner30.jpg",
                    // "img/banner40.jpg",
                    // "img/banner50.jpg",
                    // "img/banner60.jpg",
                ],
                banner02: [
                    "img/carousel_ad_1.jpg",
                    "img/carousel_ad_2.jpg",
                    "img/carousel_ad_3.jpg",
                    // 有價錢的三張拿掉
                    // "img/carousel_ad_4.jpg",
                    // "img/carousel_ad_5.jpg",
                    // "img/carousel_ad_6.jpg"
                ]
            };
        },
        components: {
            Share,
            // Plan,
            Footer,
            Banner,
            AboutUs,
            Carousel,
            Introduction,
            Description,
            ContactUs
        },
        created() {
        }
    };
</script>
