<template>
    <!-- 同意條款 -->
    <div class="my-5 step-2" data-aos="fade-up" data-aos-duration="2000">
        <b-form-checkbox id="checkbox-1"
                         v-model="status">
            <a href="/agreement"><span>本人已閱讀並同意上敘條款</span></a>
        </b-form-checkbox>
    </div>
</template>

<script>
    export default {
        name: "Step4",
        data: () => ({
            status: true
        }),
    }
</script>
